import React from "react"
import "../styles/hero.css"

export default function Hero() {
    // const AUTHOR_ICON = "../../static/icon_black.png"
    return (
        <div className="hero">
            <h1 className="hero-text">
                「ワンハリ」のホームページへようこそ！
                <br />
            </h1>
            <p>ワンコとハリーの活動やものづくりの裏側を紹介していきます！</p>
        </div>
    )
}

import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import PostLink from "../components/posts-link"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default function Home({ data }) {
    return (
        <Layout>
            <SEO
                title="ワンコとハリーのものづくり工房"
                description="ワンハリのホームページです！ワンコとハリーの活動やものづくりの裏側を紹介していきます！"
            />
            <Hero />
            {data.allContentfulWanhari.edges.map(edge => (
                <PostLink key={edge.node.slug} post={edge.node} />
            ))}
        </Layout>
    )
}

export const query = graphql`
    query MyQuery {
        allContentfulWanhari {
            edges {
                node {
                    title
                    image {
                        title
                        file {
                            url
                        }
                    }
                    description {
                        description
                    }
                    slug
                    updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
                }
            }
        }
    }
`
